@import '../../../../shared/styles/variable';
@import '../../../../shared/styles/mixin';

.sidebar-setting {

  &:not(:last-child) {
    border-bottom: @border-width-base @border-style-base @border-color-base;
    padding-bottom: 20px;
    margin-bottom: 20px;

    @media screen and (min-width: @screen-xxl) {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }
}

.sidebar-setting-title {
  margin-bottom: 16px;
}
@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;