@import '../../../shared/styles/variable';

.app-selected-icon {
  width: 20px;
  height: 20px;
  border-radius: @border-radius-circle;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;

  &.isCenter {
    right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  & svg {
    font-size: @font-size-sm;
  }
}
@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;