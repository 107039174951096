@import '../../../shared/styles/variable';

.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 20px 0;
  min-height: auto;

  @media screen and (min-width: @screen-md) {
    padding: 30px 32px 0;
  }
}
@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;