@import '../../../../shared/styles/variable';
@import '../../../../shared/styles/mixin';

.app-layout-hor {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column !important;

  & .customizer-option {
    position: fixed;
    top: 205px;

    @media screen and (min-width: @screen-xxl) {
      top: 225px;
    }
  }

  &.appMainFixedFooter {
    padding-bottom: 46px;

    & .app-main-footer {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 9;
      width: 100%;
    }
  }
}

.app-main-hor-sidebar {

  &.ant-layout-sider {
    flex: 0 0 auto !important;
    max-width: none !important;
    min-width: 0 !important;
    width: 17.5rem !important;
    .transition(all 0.1s linear);
    background-color: @sidebar-bg-color;
    color: @sidebar-text-color;
    position: fixed;
    left: 0;
    top: 0;

    [dir=rtl] & {
      left: auto;
      right: 0;
    }

    @media screen and (min-width: @screen-lg) {
      width: 17.5rem !important;
    }
  }

  & .ant-layout-sider-trigger {
    display: none;
  }

  &.ant-layout-sider-has-trigger {
    padding-bottom: 0;
  }

  // Sidebar Collapsed
  &.ant-layout-sider-collapsed {
    width: 0 !important;
    .transition(all 200ms linear);

    @media screen and (min-width: @screen-lg) {
      width: 4rem !important;
    }

    & + .app-layout-main {
      width: 100% !important;
      .transition(all 200ms linear);

      @media screen and (min-width: @screen-lg) {
        width: calc(100% - 4rem) !important;
      }
    }

    & .cr-user-info {
      padding-left: 12px;
      padding-right: 12px;
    }

    & .cr-user-info-content {
      opacity: 0;
      visibility: hidden;
      width: 0;
      padding-left: 0;
      padding-right: 0;
    }

    & .ant-menu-item-group-title {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      padding: 0;
    }
  }
}

.app-header-hor {
  color: @text-color;
  .box-shadow(0px 1px 10px 0px rgba(0,0,0,0.12));
  width: 100% !important;
  height: auto;
  line-height: 1;
  padding: 0;
  background-color: @component-background;

  & .trigger {
    font-size: 24px;
    margin-right: 20px;
    padding: 5.5px;

    @media screen and (min-width: @screen-lg) {
      display: none;
    }

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

.header-search-hor {
  position: relative;
  max-width: 140px;
  min-height: 36px;
  margin-left: auto;
  margin-right: 10px;

  [dir=rtl] & {
    margin-left: 10px;
    margin-right: auto;
  }

  @media screen and (min-width: @screen-sm) {
    margin-right: 20px;
    max-width: 165px;

    [dir=rtl] & {
      margin-right: auto;
      margin-left: 20px;
    }
  }

  & .ant-input-wrapper {
    top: 50%;
    right: 0;
    z-index: 1;
    position: absolute;
    .translateY(-50%);

    [dir=rtl] & {
      right: auto;
      left: 0;
    }
  }

  & .ant-input {
    padding: 8px 14px;
    height: 36px;
    .transition(all .2s ease);
    width: 104px;

    &:focus {
      width: 135px;
    }

    @media screen and (min-width: @screen-sm) {
      width: 129px;

      &:focus {
        width: 165px;
      }
    }
  }

  & .ant-input-search-button {
    height: 36px;
    width: 36px;
    box-shadow: none;

    & .anticon svg {
      display: block;
    }
  }

  &.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: @border-radius-base 0 0 @border-radius-base;
  }
}

.app-header-hor-sectionDesktop {
  display: none;

  @media screen and (min-width: @screen-md) {
    display: flex;
    align-items: center;
  }
}

.app-header-hor-section-mobile {
  display: block;

  @media screen and (min-width: @screen-md) {
    display: none;
  }

  & .ant-dropdown-link {
    font-size: 24px;
    color: @text-color;

    & svg {
      display: block;
    }
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: @screen-xl) {
    max-width: 1140px;
  }

  @media screen and (min-width: @screen-xxl) {
    max-width: 1440px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1720px;
  }
}

.header-alert {
  text-align: center;
  border: 0 none;
  border-radius: 0;
  border-bottom: @border-style-base @border-width-base @border-color-base;
  padding: 10.6px 20px;
  background-color: @component-background;
  display: flex;
  align-items: center;
  justify-content: center;

  & .ant-alert-content {
    flex: inherit;
  }

  & .ant-alert-close-icon {

    [dir=rtl] & {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
}

.header-hor-main {
  position: relative;
  background-color: @component-background;
  border-bottom: @border-style-base @border-width-base @border-color-base;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: @screen-md) {
    min-height: 71px;
  }

  & .cr-user-info {
    padding: 8px;
    background-color: transparent;
    min-height: auto;

    @media screen and (min-width: @screen-sm) {
      padding-left: 12px;
      padding-right: 12px;
    }

    @media screen and (min-width: @screen-md) {
      padding-left: 20px;

      [dir=rtl] & {
        padding-left: 0;
        padding-right: 20px;
      }
    }
  }

  & .cr-user-info-content {
    display: none;
    padding-right: 0;

    @media screen and (min-width: @screen-lg) {
      display: flex;
      flex-direction: column;
    }
  }

  & .cr-user-name {
    font-size: 15px;
    color: @text-color;
    line-height: 1.5;
  }

  & .cr-user-designation {
    margin-top: 2px;
    font-size: 13px;
  }

  & .cr-user-arrow {
    display: none;
  }
}

.header-hor-main-flex {
  display: flex;
  align-items: center;
}

.app-layout-hor-main {
  width: 100% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .main-content-view {
    padding-left: 0;
    padding-right: 0;
  }
}

.app-sidebar-hor-scrollbar {
  height: calc(100vh - 56px) !important;

  @media screen and (min-width: @screen-xxl) {
    height: calc(100vh - 71px) !important;
  }
}

.header-nav-hor {
  display: none;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: @border-style-base @border-width-base @border-color-base;

  @media screen and (min-width: @screen-lg) {
    display: block;
  }
}

//Horizontal Main Menu
.app-main-hor-menu {
  background-color: inherit;
  color: inherit;
  border-bottom: 0 none;

  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 10px;
    border-radius: @border-radius-base;
  }

  & .ant-menu-submenu .ant-menu-submenu-title {
    padding: 12px 10px;
  }

  &.ant-menu-horizontal > .ant-menu-item::after,
  &.ant-menu-horizontal > .ant-menu-submenu::after {
    display: none;
  }

  &.ant-menu-horizontal:not(.ant-menu-dark) {
    & > .ant-menu-item:hover,
    & > .ant-menu-submenu:hover,
    & > .ant-menu-item-active,
    & > .ant-menu-submenu-active,
    & > .ant-menu-item-open,
    & > .ant-menu-submenu-open,
    & > .ant-menu-item-selected,
    & > .ant-menu-submenu-selected {
      color: inherit;
      border-bottom: 0 none;
    }
  }

  & .ant-menu-submenu-title {
    &:hover,
    &:focus {
      color: inherit;
    }
  }

  &.ant-menu-horizontal > .ant-menu-item,
  &.ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 0 none;
  }
}

// Sidebar Background Image
.hor-sidebar-img-background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    z-index: 1;
    opacity: 0.5;
  }

  & > div {
    position: relative;
    z-index: 3;
  }

  & .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: transparent;
    color: inherit;
  }

  & .app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
  & .app-main-sidebar-menu .ant-menu-item a {
    color: inherit;
  }
}

// Drawer
.app-hor-drawer {
  & .ant-drawer-content-wrapper {
    width: 17.5rem !important;
  }

  & .ant-drawer-body {
    padding: 0;
  }
}

@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;