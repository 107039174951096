@import '../../../../shared/styles/variable';
@import '../../../../shared/styles/mixin';

.app-BitBucket-layout {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;

  &.appMainFixedFooter {
    padding-bottom: 46px;

    & .app-main-footer {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      width: 100%;
    }
  }
}

.ant-layout-sider-zero-width-trigger {
  display: none;
}

.app-BitBucket-sidebar-wrapper {
  display: none;
  width: 320px !important;
  .transition(all 0.1s linear);
  position: relative;

  border-right: @border-style-base @border-width-base @border-color-base;
  @media screen and (min-width: @screen-xl) {
    display: flex;
  }
}

.app-BitBucket-sidebar-wrapper-close {
  width: 72px !important;

  & .app-BitBucket-sidebar.ant-layout-sider {
    min-width: 0 !important;
    width: 8px !important;
    .transition(all 0.1s linear);
    overflow: hidden;
  }
}

.bitbucket-btn {
  position: absolute;
  top: 20px;
  right: -12px;
  z-index: 3;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @primary-color;
  color: @text-color-white;
  padding: 2px;
  font-size: 24px;
  border-radius: @border-radius-circle;

  [dir=rtl] & {
    right: auto;
    left: -12px;
  }

  &:hover,
  &:focus {
    background-color: @primary-color;
    color: @text-color-white;
  }
}

.bucket-minibar {
  display: flex;
  flex-direction: column;
  width: 64px;
  min-width: 64px;
  background-color: @sidebar-dark-bg-color;
  color: inherit;
  border-right: @border-width-base @border-style-base fade(@sidebar-dark-bg-color, 15);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: fade(@black, 5);
  }

  &.dark {
    &:before {
      background-color: fade(@white, 5);
    }
  }

  & .bucket-minibar-inner {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
  }

  [dir=rtl] & {
    border-right: 0 none;
    border-left: @border-width-base @border-style-base fade(@sidebar-dark-bg-color, 15);
  }

  & a {
    color: inherit;
  }
}

.bucket-minibar-logo {
  min-height: 56px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: @screen-md) {
    min-height: 71px;
  }
}

.bucket-minibar-scrollbar {
  display: flex;
  flex-direction: column;
  padding: 12px;
  height: calc(100vh - 56px);

  @media screen and (min-width: @screen-md) {
    height: calc(100vh - 71px);
  }
}

.bucket-minibar-main {
  text-align: center;
  display: flex;
  flex-direction: column;

  & .bucket-minibar-link {
    margin-bottom: 10px;
    font-weight: @font-weight-medium;
    text-transform: uppercase;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: inherit;
    padding: 9px;
    border-radius: @border-radius-circle;
    line-height: 1;
  }

  & .bucket-minibar-notify-link {
    font-size: 20px;
  }

  & .langBtn {
    margin: 0 0 10px;
    background-color: transparent;

    &:hover, &:focus {
      border-color: transparent;
      color: inherit;
    }
  }

  & .lang-text {
    display: none;
  }
}

.bucket-minibar-setting {
  text-align: center;
  margin-top: auto;
}

.app-BitBucket-sidebar {
  &.ant-layout-sider {
    flex: 0 0 auto !important;
    max-width: none !important;
    min-width: 256px !important;
    width: auto !important;
    .transition(all 0.1s linear);

    @media screen and (min-width: 1200px) and (max-width: 1300px) {
      width: 190px !important;
    }
  }

  & .ant-layout-sider-trigger {
    display: none;
  }

  &.ant-layout-sider-has-trigger {
    padding-bottom: 0;
  }

  // Sidebar Collapsed
  &.ant-layout-sider-collapsed {
    width: 0 !important;
    .transition(all 200ms linear);

    @media screen and (min-width: @screen-lg) {
      width: 4rem !important;
    }

    & + .app-layout-main {
      width: 100% !important;
      .transition(all 200ms linear);

      @media screen and (min-width: @screen-lg) {
        width: calc(100% - 4rem) !important;
      }
    }

    & .cr-user-info {
      padding-left: 12px;
      padding-right: 12px;
    }

    & .cr-user-info-content {
      opacity: 0;
      visibility: hidden;
      width: 0;
      padding-left: 0;
      padding-right: 0;
    }

    & .ant-menu-item-group-title {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      padding: 0;
    }
  }
}

.app-BitBucket-header {
  padding-left: 20px;
  padding-right: 20px;
  color: @text-color;
  background-color: @component-background;
  height: 56px;
  line-height: 1;
  .box-shadow(4px 3px 4px 0px rgba(0, 0, 0, 0.12));
  .transition(all 0.1s linear);
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;

  @media screen and (min-width: @screen-md) {
    padding-left: 30px;
    padding-right: 30px;
    height: 71px;
  }

  @media screen and (min-width: @screen-xl) {
    display: none;
  }

  & .trigger {
    font-size: 20px;
    margin-right: 20px;
    padding: 5.5px;
    color: @text-color;

    & svg {
      display: block;
    }

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 20px;
    }

    @media screen and (min-width: @screen-xl) {
      display: none;
    }
  }
}

.bitBucket-header-search {
  position: relative;
  max-width: 140px;
  min-height: 36px;
  margin-left: auto;
  margin-right: 10px;

  [dir=rtl] & {
    margin-left: 10px;
    margin-right: auto;
  }

  @media screen and (min-width: @screen-sm) {
    margin-right: 20px;
    max-width: 165px;

    [dir=rtl] & {
      margin-right: auto;
      margin-left: 20px;
    }
  }

  & .ant-input-wrapper {
    top: 50%;
    right: 0;
    z-index: 1;
    position: absolute;
    .translateY(-50%);

    [dir=rtl] & {
      right: auto;
      left: 0;
    }
  }

  & .ant-input {
    padding: 8px 14px;
    height: 36px;
    .transition(all .2s ease);
    width: 104px;

    &:focus {
      width: 135px;
    }

    @media screen and (min-width: @screen-sm) {
      width: 129px;

      &:focus {
        width: 165px;
      }
    }
  }

  & .ant-input-search-button {
    height: 36px;
    width: 36px;
    box-shadow: none;

    & .anticon svg {
      display: block;
    }
  }

  &.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: @border-radius-base 0 0 @border-radius-base;
  }
}

.app-BitBucket-header-sectionDesktop {
  display: none;

  @media screen and (min-width: @screen-md) {
    display: flex;
    align-items: center;
  }
}

.app-BitBucket-header-section-mobile {
  display: block;

  @media screen and (min-width: @screen-md) {
    display: none;
  }

  & .ant-dropdown-link {
    font-size: 24px;
    color: @text-color;

    & svg {
      display: block;
    }
  }
}

.app-BitBucket-layout-main {
  .transition(all 0.1s linear);
  width: 100% !important;
  position: relative;

  @media screen and (min-width: @screen-xl) {
    width: calc(100% - 320px) !important;
  }

  & .apps-container {

    @media screen and (min-width: @screen-xl) {
      height:  calc(100vh - 78px);
    }
  }
}

.bitBucket-main-scrollbar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - 56px);

  .appMainFixedFooter & {
    max-height: calc(100vh - 102px);
  }

  @media screen and (min-width: @screen-md) {
    max-height: calc(100vh - 71px);

    .appMainFixedFooter & {
      max-height: calc(100vh - 116px);
    }
  }

  @media screen and (min-width: @screen-xl) {
    max-height: 100vh;

    .appMainFixedFooter & {
      max-height: calc(100vh - 48px);
    }
  }
}

.app-BitBucket-sidebar-scrollbar {
  height: calc(100vh - 56px);

  .appMainFixedFooter & {
    height: calc(100vh - 102px);
  }

  @media screen and (min-width: @screen-md) {
    height: calc(100vh - 71px);

    .appMainFixedFooter & {
      height: calc(100vh - 116px);
    }
  }
}

// Sidebar Background Image
.bitBucket-sidebar-img-background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    z-index: 1;
    opacity: 0.5;
  }

  & > div {
    position: relative;
    z-index: 3;
  }

  & .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: transparent;
    color: inherit;
  }

  & .app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
  & .app-main-sidebar-menu .ant-menu-item a {
    color: inherit;
  }
}

//BitBucket Drawer
.app-BitBucket-drawer {

  & .ant-drawer-content-wrapper {
    width: 20rem !important;
  }

  & .ant-drawer-body {
    padding: 0;
  }

  & .app-BitBucket-sidebar-wrapper {
    display: flex;
    width: auto !important;
  }

  & .cr-user-info {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}


@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;