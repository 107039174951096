.b-container.banner {
  width: 100%;
  aspect-ratio: 4956/2372;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: start;
  background-size: 100% auto;
  background-position: top;

  .banner--title {
    font-size: 60px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    color: #3973b9;
    // backgroundColor: #ffffffb0;
    border-radius: 5px;
    line-height: 100%;
  }

  .banner--subtitle {
    text-transform: uppercase;
    font-size: 30px;
  }

  .glass {
    width: max-content;
    margin-top: 0;
    padding: 4em;
  }
}

.tracking-form {
  flex-direction: column;
  margin-top: 6em;
}

.detail-tracking {
  .order-detail {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    border-right: 1px solid #efefef;
  }

  .detail-tracking-wrapper {
    width: 100%;
    padding: 70px 0 0;
  }

  .tracking-label {
    font-size: 30px;
  }

  .orderLog-wrapper {
    min-height: 300px;
    margin-bottom: 0;
    padding-left: 3em;
    margin-top: 1em;
  }
}

@media screen and (max-width: 900px) {
  .b-container.banner {
    height: max-content;
    padding: 10px;
    .glass {
      width: 100%;
      margin-top: 0;
      padding: 20px;
    }

    .banner--title {
      font-size: 30px;
    }
    .banner--subtitle {
      font-size: 25px;
    }
    .tracking-form {
      flex-direction: column;
    }
  }
  .detail-tracking {
    .order-detail {
      border-right: none;
      padding: 0 !important;
    }

    .detail-tracking-wrapper {
      width: 100%;
      padding: 30px 0 0;
    }
    .tracking-label {
      font-size: 20px;
    }

    .orderLog-wrapper {
      min-height: unset;
    }
  }
}
