@import '../../../../../shared/styles/variable';
@import '../../../../../shared/styles/mixin';

.nav-menu-style {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.nav-menu-style-item {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.nav-menu-style-item-cur {
  position: relative;
  cursor: pointer;
}
@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;