@import '../../../shared/styles/variable';
.ant-row {
  & > .ant-col {
    margin-bottom: @col-margin-res;

    @media screen and (min-width: @screen-md) {
      margin-bottom: @col-margin-base;
    }

    .ant-form & {
      margin-bottom: 0;
    }
  }

  &.ant-form-item > .ant-col {
    margin-bottom: 0;
  }
}
@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;