@import "../../../../../shared/styles/variable";
@import "../../../../../shared/styles/mixin";

.app-logo {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  & img {
    height: 45px;
    margin-right: 5px;
    // border-radius: 5px;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}

.app-logo-name {
  font-weight: @font-weight-medium;
  font-size: 24px;
  display: none;

  @media screen and (min-width: @screen-sm) {
    display: block;
  }

  &.white {
    color: @text-color-white;
  }
}

@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;