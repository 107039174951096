@import "./shared/styles/variable.less";

.order-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.App{
  min-height: 100vh;
}

.order-header-input-view {
  max-width: 120px;

  @media screen and (min-width: @screen-sm) {
    max-width: 150px;
  }
}

.order-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: 10px;
  }
}

.order-header-pagination {
  display: none;
  padding-left: 12px;
  padding-right: 2px;

  [dir="rtl"] & {
    padding-left: 2px;
    padding-right: 12px;
  }

  @media screen and (min-width: @screen-md) {
    display: block;
  }
}

.order-footer-pagination {
  display: block;
  padding: 10px;

  @media screen and (min-width: @screen-md) {
    display: none;
  }
}

.order-table {
  & .ant-table table {
    table-layout: auto !important;
  }

  & .ant-table-thead > tr > th {
    font-size: 13px;
    padding: 8px;
    font-weight: @font-weight-bold;
    background-color: transparent;

    &:first-child {
      padding-left: 20px;

      [dir="rtl"] & {
        padding-left: 0;
        padding-right: 20px;
      }
    }

    &:last-child {
      padding-right: 20px;

      [dir="rtl"] & {
        padding-right: 0;
        padding-left: 20px;
      }
    }

    &.order-table-action {
      text-align: center;
      background-color: @component-background !important;
    }
  }

  & .ant-table-tbody > tr > td {
    font-size: 13px;
    padding: 12px 8px;

    &:first-child {
      padding-left: 20px;

      [dir="rtl"] & {
        padding-left: 0;
        padding-right: 20px;
      }
    }

    &:last-child {
      padding-right: 20px;

      [dir="rtl"] & {
        padding-right: 0;
        padding-left: 20px;
      }
    }

    &.order-table-action {
      text-align: center;
      background-color: @component-background !important;
    }
  }

  & .badge {
    padding: 3px 10px;
    border-radius: @card-radius;
    display: inline-block;
  }
}

.order-id {
  text-decoration: underline;
  color: @primary-color;
}

@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;