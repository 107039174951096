@import '../../../shared/styles/variable';

.notify-listItem {
  padding: 8px 20px;
  border-bottom: 0 none !important;
  transition: .4s;


  &:hover {
    background-color: rgba(10,143,220,.1);
    transform: translateY(-2px);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%);
  }

  & .ant-list-item-meta {
    align-items: center;
  }

  &.unread {
     background-color: rgba(10,143,220,.1);
  }

  & .ant-list-item-meta-avatar {
    margin-right: 12px;

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 12px;
    }

    @media screen and (min-width: @screen-md) {
      margin-right: 16px;

      [dir=rtl] & {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  & .ant-list-item-meta-title {
    margin-bottom: 2px;
  }
}

.notify-message-avatar {
  width: 48px;
  height: 48px;
}

@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;