@import '../../../shared/styles/variable';
.list-footer {
  padding: 10px;
  color: @text-color-secondary;
  display: flex;
  justify-content: center;
}

.loader-progress {
  width: 100%;
  display: flex;
  justify-content: center;
  color: @text-color-secondary;
  padding: 8px;

  & span {
    margin-left: 8px;

    [dir=rtl] & {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

.grid {
  &-container {
    width: 100%;
  }

  &-column-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-column-count {
    flex-grow: 0;
    box-sizing: border-box;
  }
}
@primary-color: #3973b9;@border-radius-base: 5px;@form-item-margin-bottom: 10px;@form-item-trailing-colon: true;@form-vertical-label-padding: 0 0 8px;@form-vertical-label-margin: 0;@descriptions-title-margin-bottom: 5px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@font-size-base: 12px;@alert-with-description-padding: 4px 8px;